.Home {
  text-align: center;
  background: url("background_5.jpg"), #fafaf7; 
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1200px auto;
  background-attachment: fixed;
  overflow-x: hidden;
  padding-top:10vh;
  height: 100vh;
}

@media screen and (max-width: 600px) {
    .Home {
        background-size: 600px auto;
    }
}


.ToDelete {
   background: url("background_3.jpg"), #d1d9e6; 
}

.Location {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9% auto;
  background-color: #d1d9e6;
  background-attachment: fixed;
  padding-top:10vh;
}

.Hochzeit {
     padding-top:3vh;
    text-align: center;
  background-color: #fafaf7;
}

.Zeitplan {
     padding-top:10vh;
    text-align: center;
  background-color: #fafaf7;
}


.Accomodation {
    padding-top:10vh;
    background-color: #fafaf7;
    
}

.Programm  {
    padding-top:10vh;
    text-align: center;
    background-color: #fafaf7;
}


@media screen and (min-width: 601px) {
    .LocationPics {
        width: 65%;
    }
}
@media screen and (max-width: 600px) {
   .LocationPics {
    width: 100%;
    }
}


.Schrift {
	font-size: 2.5vw; 
	color: dark-blue;
	font-family: Comic Sans MS;
	
}

.SchriftAcco {
    font-size: 20px; 
	color: dark-blue;
	font-family: Comic Sans MS;
    padding-left: 30px;
}

.SchriftGroesser {
	color: dark-blue;
	font-family: Comic Sans MS;
}

.BigWeddingDateDiv {
    background-color: #216477;
    
}

.BigWeddingDate {
    font-size: 11vw;
    color: ivory;
}

.fadeIn {
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
   
}
 
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     } 
}


.fadeInLong {
    animation: fadeInAnimation ease 6s;
    animation-iteration-count: 1;
   
}


@media screen and (min-width: 601px) {
  .Schrift {
    font-size: 22px;
  }
  .Einladungsbild {
    width: 800px;
  }
  .UeberschriftHochzeit {
      font-size: 50px;
  }
}

@media screen and (max-width: 600px) {
  .Einladungsbild {
    width: 95%;
  }
}

@media screen and (min-width: 828px) {
   .HamburgerSymbol { 
        display:none;  
   }
}
    
@media screen and (max-width: 827px) {
   .nav-links {
        display:none;
        align: left;
    }
}
    

.buttonTable {
  font-size: 22px;
  background-color: #333;
  padding: 10px 20px;
  margin-right:auto;
  margin-left:auto;
  width: 98%;
}




.accoTable {
    width: 90%;
    padding: 10px 20px;
    margin-right: auto;
    margin-left: auto;
    background-color: beige;
    font-size: 22px;
}

.contactTable {
    width: 90%;
    padding: 10px 20px;
    margin-right: auto;
    margin-left: auto;
    background-color: beige;
    font-size: 22px;
}

input {
    font-size: 3vh;
}

.inputText {
    font-size: 2.6vh;
    width: 90%;
    max-width: 400px;
}

.WichtigeInfo {
	background-color: white;
	width: 80%;
	max-width: 700px;
	margin: auto;
	border: 3px solid green;
}

.Ueberschrift {
	font-size: 5vh; 
	color: darkslategrey;
	font-family: Calibri;
	letter-spacing: 1px;
	margin-top:15px;
	padding-top: 40px;
    margin-left: 2vh;
    text-align: left;
}

.UeberschriftHochzeit {
	color: darkslategrey;
	font-family: Calibri;
	letter-spacing: 1px;
	margin-top:15px;
	padding-top: 40px;
}

.Ueberschrift2 {
	font-size: 3.5vh; 
	color: black;
	font-family: Calibri;
	letter-spacing: 1px;
    margin-left: 2vh;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-link {
  color: #61dafb;
}



nav {
  justify-content: space-between;
  background-color: #333;
  padding: 10px 20px;
  margin-right:155px;
  position:fixed;
  width: 98%;
  
}




.nav-links a {
  float: left;
  display: block;
  position: relative;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.nav-links a:hover {
  color: #f39c12;
}

label.hamburg { 
   display: block;
   background: #555; width: 75px; height: 50px; 
   position: relative; 
   margin-left: 10px; margin-right: auto;
   border-radius: 4px; 
}

input#hamburg {display:none}

.line { 
   position: absolute; 
   left:10px;
   height: 4px; width: 55px; 
   background: #fff; border-radius: 2px;
   display: block; 
   transition: 0.5s; 
   transform-origin: center; 
}

.line:nth-child(1) { top: 12px; }
.line:nth-child(2) { top: 24px; }
.line:nth-child(3) { top: 36px; }

#hamburg:checked + .hamburg .line:nth-child(1){
   transform: translateY(12px) rotate(-45deg);
}

#hamburg:checked + .hamburg .line:nth-child(2){
   opacity:0;
}

#hamburg:checked + .hamburg .line:nth-child(3){
   transform: translateY(-12px) rotate(45deg);
}


.hamburgNavOpen {
  overflow: hidden;
  background-color: #333;
}

.hamburgNavOpen a {
  float: none;
  display: block;
  position: relative;
  color: #f2f2f2;
  text-align: left;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.hamburgNavOpen a:hover {
  background-color: #ddd;
  color: black;
}

.hamburgNavOpen a.active {
  background-color: #04AA6D;
  color: white;
}

.hamburgNavClosed {
    display:none;
}

